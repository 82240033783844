import Spinner from './Spinner';

export default function Button(props: {
  onClick: () => void;
  loading?: boolean;
  title?: string;
}) {
  const { onClick, loading, title } = props;
  return (
    <button
      onClick={() => {
        if (!loading) {
          onClick();
        }
      }}
      className="flex items-center justify-center font-medium shadow-xl text-gray"
    >
      <div className="text-gray">
        {loading ? (
          <Spinner style={{ transform: 'scale(0.25)' }} />
        ) : (
          title || 'SUBMIT INQUIRY'
        )}
      </div>
    </button>
  );
}
