import {
  DateSelection,
  Dropdown,
  MultipleChoiceQuestion,
  ShortAnswerQuestion,
  ShortAnswerType,
  Text as TextType,
} from '../models/Question.interface';

// initial placeholders

const shortQuesPlaceholders: { [key in ShortAnswerType]: string } = {
  FullName: 'What is your full name?',
  Email: 'What is your email?',
  PhoneNumber: 'What is your best contact number?',
  Address: 'What is your address?',
  NoType: '',
};

const shortAnsPlaceholders: { [key in ShortAnswerType]: string } = {
  FullName: 'Full Name',
  Email: 'Email',
  PhoneNumber: 'Phone Number',
  Address: 'Address',
  NoType: 'This is the placeholder text',
};

const DateQuestion: DateSelection = {
  questionType: 'DateSelection',
  answered: false,
  title: '',
  precedence: 0,
  answer: '',
  required: false,
  randomized: false,
  time: false
};

// initial questions
const ShortTextQuestion: ShortAnswerQuestion = {
  questionType: 'ShortAnswer',
  answered: false,
  title: '',
  precedence: 0,
  required: false,
  randomized: false,
  answer: '',
  shortAnswerType: 'NoType',
};

const MultipleQuestion: MultipleChoiceQuestion = {
  questionType: 'Choice',
  answered: false,
  title: '',
  precedence: 0,
  required: false,
  randomized: false,
  choices: [
    {
      title: '',
      subtitle: '',
      photoRef: '',
      placeholder: 'Option 1'
    },
  ],
  answer: [],
  choiceType: 'MultipleChoice',
  singleSelection: true
};

const DropdownQuestion: Dropdown = {
  questionType: 'Dropdown',
  answered: false,
  title: '',
  required: false,
  precedence: 0,
  choices: [
    {
      title: '',
      subtitle: '',
      photoRef: '',
    },
  ],
  answer: [],
  singleSelection: false,
  choiceType: 'Dropdown',
  randomized: false,
  placeholder: '',
};

const ParagraphQuestion: TextType = {
  questionType: 'Paragraph',
  answered: false,
  title: '',
  precedence: 0,
  required: false,
  placeholder: '',
  randomized: false,
};

const MultipleSelectQuestion: MultipleChoiceQuestion = {
  ...MultipleQuestion,
  singleSelection: false
};

const FullNameQuestion: ShortAnswerQuestion = {
  ...ShortTextQuestion,
  shortAnswerType: 'FullName',
  title: shortQuesPlaceholders['FullName'],
};

const EmailQuestion: ShortAnswerQuestion = {
  ...ShortTextQuestion,
  shortAnswerType: 'Email',
  title: shortQuesPlaceholders['Email'],
};

const PhoneQuestion: ShortAnswerQuestion = {
  ...ShortTextQuestion,
  shortAnswerType: 'PhoneNumber',
  title: shortQuesPlaceholders['PhoneNumber'],
};

const AddressQuestion: ShortAnswerQuestion = {
  ...ShortTextQuestion,
  shortAnswerType: 'Address',
  title: shortQuesPlaceholders['Address'],
};

export {
  ShortTextQuestion,
  FullNameQuestion,
  shortQuesPlaceholders,
  shortAnsPlaceholders,
  EmailQuestion,
  PhoneQuestion,
  AddressQuestion,
  MultipleQuestion,
  MultipleSelectQuestion,
  DateQuestion,
  DropdownQuestion,
  ParagraphQuestion,
};
