import DateSelection from '../components/Home/Questions/DateSelection';
import Dropwdown from '../components/Home/Questions/Dropdown';
import FileUpload from '../components/Home/Questions/FileUpload';
import MultiChoice from '../components/Home/Questions/MultiChoice';
import Paragraph from '../components/Home/Questions/Paragraph';
import ShortText from '../components/Home/Questions/ShortText';
import { shortAnsPlaceholders } from '../constants/QuestionConstants';
import type { Choice, Question } from '../models/Question.interface';

function generateQuestion(
  question: Question,
  quesNo: number,
  accId: string,
  orderFormId: string,
  handleFieldValue: (index: any, newValues: any) => void,
  excludeDateRange: Date[] = [],
  invalid?: string[]
) {
  function getFieldsShortText() {
    const fields = [];
    if (question.questionType === 'ShortAnswer' && question.shortAnswerType) {
      const placeholderArr = question?.placeholder?.split(';');
      const answerArr = question?.answer?.split(';');
      if (question.shortAnswerType === 'FullName') {
        fields.push({
          placeholder:
            placeholderArr && placeholderArr[0]
              ? placeholderArr[0]
              : 'First Name',
          containerClassName: 'w-full mdw49 w-full',
          value: answerArr && answerArr[0] ? answerArr[0] : '',
        });
        fields.push({
          placeholder:
            placeholderArr && placeholderArr[1]
              ? placeholderArr[1]
              : 'Last Name',
          containerClassName: 'w-full mdw49 w-full  mdml2',
          value: answerArr && answerArr[1] ? answerArr[1] : '',
        });
      } else if (question.shortAnswerType === 'Address') {
        fields.push({
          placeholder:
            placeholderArr && placeholderArr[0]
              ? placeholderArr[0]
              : 'Street Address',
          containerClassName: 'w-full',
          value: answerArr && answerArr[0] ? answerArr[0] : '',
        });
        fields.push({
          placeholder:
            placeholderArr && placeholderArr[1] ? placeholderArr[1] : 'City',
          containerClassName: 'w-full',
          value: answerArr && answerArr[1] ? answerArr[1] : '',
        });
        fields.push({
          placeholder:
            placeholderArr && placeholderArr[2] ? placeholderArr[2] : 'State',
          containerClassName: 'w49 ',
          value: answerArr && answerArr[2] ? answerArr[2] : '',
        });
        fields.push({
          placeholder:
            placeholderArr && placeholderArr[3]
              ? placeholderArr[3]
              : 'Zip Code',
          containerClassName: 'w49 ml2',
          value: answerArr && answerArr[3] ? answerArr[3] : '',
        });
      } else {
        fields.push({
          placeholder:
            placeholderArr && placeholderArr[0]
              ? placeholderArr[0]
              : shortAnsPlaceholders[question.shortAnswerType],
          containerClassName: 'w-full',
          value: answerArr && answerArr[0] ? answerArr[0] : '',
        });
      }
      return fields;
    }
    return [];
  }

  if (question.questionType === 'ShortAnswer') {
    return (
      <ShortText
        title={`${question.title}${
          question.required || question.systemQuestion ? '*' : ''
        }`}
        quesNo={quesNo}
        fields={getFieldsShortText()}
        question={question}
        handleValue={handleFieldValue}
        invalid={invalid}
      />
    );
  } else if (question.questionType === 'DateSelection') {
    return (
      <DateSelection
        title={`${question.title}${
          question.required || question.systemQuestion ? '*' : ''
        }`}
        quesNo={quesNo}
        time={question.time}
        value={question.answer}
        question={question}
        handleValue={handleFieldValue}
        excludeDateRange={excludeDateRange}
        invalid={invalid}
      />
    );
  } else if (question.questionType === 'Dropdown') {
    return (
      <Dropwdown
        title={`${question.title}${question.required ? '*' : ''}`}
        quesNo={quesNo}
        choices={question.choices}
        placeholder={question.placeholder}
        accId={accId}
        orderFormId={orderFormId}
        value={question.choiceAnswers}
        handleValue={handleFieldValue}
        question={question}
        invalid={invalid}
      />
    );
  } else if (question.questionType === 'Choice') {
    return (
      <MultiChoice
        title={`${question.title}${question.required ? '*' : ''}`}
        quesNo={quesNo}
        choices={question.choices as Choice[]}
        accId={accId}
        orderFormId={orderFormId}
        value={question.answer}
        handleValue={handleFieldValue}
        question={question}
        invalid={invalid}
      />
    );
  } else if (question.questionType === 'Paragraph') {
    return (
      <Paragraph
        title={`${question.title}${question.required ? '*' : ''}`}
        question={question}
        quesNo={quesNo}
        handleValue={handleFieldValue}
        invalid={invalid}
      />
    );
  } else if (question.questionType === 'FileUpload') {
    return (
      <FileUpload
        title={`${question.title}${question.required ? '*' : ''}`}
        question={question}
        quesNo={quesNo}
        accId={accId}
        orderFormId={orderFormId}
        handleValue={handleFieldValue}
        invalid={invalid}
      />
    );
  }
  return null;
}

export { generateQuestion };
