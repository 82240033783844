import Footer from './Footer';
import Header from './Header';

export default function Layout(props: {
  isProUser?: boolean;
  children: React.ReactChild;
  isLoading?: boolean;
  informationalPage?: boolean;
}) {
  return (
    <div>
      {!props.isLoading && (
        <Header
          isProUser={props.isProUser || false}
          informationalPage={props.informationalPage}
        />
      )}
      {props.children}
      {!props.isLoading && <Footer isProUser={props.isProUser || false} />}
    </div>
  );
}
