import AppRoute from './routes';
import Amplify from '@aws-amplify/core';
import { AppConfig } from './constants/aws-config';

function App() {
  Amplify.configure({
    ...AppConfig.amplifyConfig,
    Analytics: {
      disabled: true,
    },
  });

  return <AppRoute />;
}

export default App;
