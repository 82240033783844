import Logo from '../assets/images/logo-white.png';

export default function Footer(props: { isProUser: boolean }) {
  return (
    <>
      {!props.isProUser && (
        <div className=" bg-clifford flex items-center justify-center py-8 flex-col  ">
          <img src={Logo} className="h-20" alt="logo" />
          <div className="font-thin text-white font-roboto my-2">
            hello@siftmobileapp.com
          </div>
          <div className="grid grid-cols-3 gap-2 my-2">
            <div className="h-10 w-10 rounded-full border-2 border-white  flex items-center justify-center">
              <i className="fab fa-facebook text-white"></i>
            </div>
            <div className="h-10 w-10 rounded-full border-2 border-white flex items-center justify-center">
              <i className="fab fa-twitter text-white"></i>
            </div>
            <div
              onClick={() => {
                window.open('https://www.instagram.com/simplysift/');
              }}
              className="h-10 w-10 rounded-full border-2 border-white flex items-center justify-center cursor-pointer"
            >
              <i className="fab fa-instagram text-white"></i>
            </div>
          </div>
        </div>
      )}
      <div className="bg-robin py-6 grid md:grid-cols-2 grid-cols-1">
        <div className="md:flex">
          <div
            onClick={() => {
              window.open(
                'https://app.termly.io/document/terms-of-use-for-ios-app/7eb8dbd6-7c72-4cc4-95ba-154be6ddb635'
              );
            }}
            className="text-white w-full text-center md:text-left md:w-auto md:mx-12 font-roboto font-medium	"
          >
            Terms of Service
          </div>
          <div
            onClick={() => {
              window.open(
                'https://app.termly.io/document/privacy-policy/0864b898-c92f-4169-8acd-bb7ecef7e901'
              );
            }}
            className="text-white w-full md:w-auto font-roboto text-center md:text-left font-medium mt-2 md:mt-0"
          >
            Privacy Policy
          </div>
        </div>
        <div className="flex md:justify-end justify-center">
          <div className="text-white mx-12 font-medium font-roboto flex mt-6 md:mt-0 ">
            <p>
              <i className="far fa-copyright"></i>
            </p>
            &ensp; 2021 Retamozo Group LLC. All Rights Reserved.
          </div>
        </div>
      </div>
    </>
  );
}
