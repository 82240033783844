import { useEffect, useState } from 'react';
import type { Text } from '../../../models/Question.interface';
import QuestionTitle from './QuestionTitle';

export default function Paragraph(props: {
  title?: string;
  quesNo?: number;
  question: Text;
  handleValue: (index: any, newValues: any) => void;
  invalid?: string[];
}) {
  const { quesNo, title, question, handleValue, invalid } = props;

  const [dirty, setDirty] = useState(false);

  // on invalid update dirty
  useEffect(() => {
    setDirty(false);
  }, [invalid]);

  return (
    <div className="mt-8">
      <QuestionTitle quesNo={quesNo} title={title} />
      <div
        className={`mt-4 box_shadow_input bg-white ${
          !!invalid && !dirty && 'invalid'
        }`}
      >
        <textarea
          className="short_text_input"
          placeholder={question.placeholder}
          rows={7}
          onChange={(e) => {
            let text = e.target.value;
            handleChange(text, 'answer');
          }}
        />
      </div>
    </div>
  );

  function handleChange(value: string | boolean, field: string) {
    if (!dirty) {
      setDirty(true);
    }

    let newValues = {
      ...question,
      [field]: value,
      answered: true,
    };
    handleValue(quesNo ? +quesNo - 1 : 0, newValues);
  }
}
