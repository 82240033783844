import { Fragment, useEffect, useState } from 'react';
import { ChoiceInput, Dropdown } from '../../../models/Question.interface';
import ChevronDown from '../../Icons/Down';
import OptionImg from './OptionImg';
import QuestionTitle from './QuestionTitle';
import RadioCircle from './RadioCircle';
import Collapsible from 'react-collapsible';

export default function Dropwdown(props: {
  title?: string;
  quesNo?: number;
  choices: ChoiceInput[];
  placeholder?: string;
  accId?: string;
  orderFormId: string;
  value?: any;
  handleValue: (index: any, newValues: any) => void;
  question: Dropdown;
  invalid?: string[];
}) {
  const {
    quesNo,
    title,
    choices,
    placeholder,
    accId,
    orderFormId,
    value,
    question,
    handleValue,
    invalid,
  } = props;

  const [opened, setOpened] = useState<boolean>(false);
  const [dirty, setDirty] = useState(false);

  // on invalid update dirty
  useEffect(() => {
    setDirty(false);
  }, [invalid]);

  useEffect(() => {
    if (!placeholder) {
      handleChange('Select', 'placeholder');
    }
  }, [placeholder]);

  return (
    <div className="mt-8">
      <QuestionTitle quesNo={quesNo} title={title} />

      <Collapsible
        trigger={
          <div
            className={`dropdown_placeholder_cont ${
              !!invalid && !dirty && 'invalid'
            }`}
            onClick={() => {
              setOpened(!opened);
            }}
          >
            <div className="short_text_input text-gray">
              {placeholder || 'Select'}
            </div>
            <div className={`${opened ? 'rotate-180' : ''}`}>
              <ChevronDown />
            </div>
          </div>
        }
      >
        {opened &&
          choices?.map((choice, index) => {
            return (
              <Fragment key={index}>
                <div
                  className={` bg-white flex items-center pl-4 cursor-pointer`}
                  onClick={() => {
                    const updatedChoices = [
                      {
                        title: choice.title,
                        subtitle: '',
                        photoRef: '',
                      },
                    ];
                    handleChange(updatedChoices, 'choiceAnswers');
                  }}
                >
                  <RadioCircle
                    selected={
                      value?.find((e: any) => e.title === choice.title)
                        ? true
                        : false
                    }
                  />
                  <div className="short_text_input">{choice.title}</div>
                </div>
                {choice?.photoRef && (
                  <div className="my-2">
                    <OptionImg
                      photo={choice?.photoRef}
                      accId={accId || ''}
                      orderFormId={orderFormId}
                    />
                  </div>
                )}
              </Fragment>
            );
          })}
      </Collapsible>
    </div>
  );

  function handleChange(value: string | boolean | any[], field: string) {
    if (!dirty) {
      setDirty(true);
    }

    let newValues = {
      ...question,
      [field]: value,
      answered: true,
    };
    handleValue(quesNo ? quesNo - 1 : 0, newValues);
  }
}
