import { useEffect, useState } from 'react';
import { getInitials } from '../utils/common';
import { getPhotoUrl } from '../utils/photo';

export default function ProfileImg(props: {
  banner: string;
  accId: string;
  companyName: string;
}) {
  const { banner, accId, companyName } = props;

  const [uri, setUri] = useState<string>('');

  const [error, setError] = useState<boolean>(false);

  async function getBannerPhoto() {
    try {
      const url = await getPhotoUrl({
        photoRef: banner,
        orderId: '',
        type: undefined,
        folderId: accId,
        isAccountPhoto: true,
      });
      if (url.uri) {
        setUri(url.uri);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (banner && accId) {
      getBannerPhoto();
    }
  }, [banner, accId]);
  return (
    <>
      {uri && !error && (
        <img
          src={uri}
          alt="profile"
          onError={() => {
            setError(true);
          }}
        />
      )}
      {(!uri || error) && (
        <div className="w-full h-full flex items-center justify-center text-2xl text-white font-roboto">
          {getInitials(companyName || '') || ''}
        </div>
      )}
    </>
  );
}
