export default function RedirectText(props: {
  title?: string;
  onClick?: () => void;
}) {
  const { title, onClick } = props;
  return (
    <div className="text-center cursor-pointer" onClick={onClick}>
      <p className="text-gray font-serif">{title}</p>
    </div>
  );
}
