import { SVGProps } from 'react';
import colors from '../../constants/colors';

const ChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill={colors.primary.color1}
    height="17"
    width="17"
    {...props}
  >
    <g data-name="arrow down">
      <path d="M8 16a8 8 0 1 0-8-8 8 8 0 0 0 8 8ZM8 1.33A6.65 6.65 0 1 1 1.37 8 6.65 6.65 0 0 1 8 1.33Z" />
      <path d="M5.05 6.07a.47.47 0 0 1 .35.15l2.29 2.43a.48.48 0 0 0 .71 0l2.29-2.43a.48.48 0 0 1 .71 0 .56.56 0 0 1 0 .75L9.1 9.4a1.47 1.47 0 0 1-2.1 0L4.69 7a.55.55 0 0 1 0-.75.49.49 0 0 1 .36-.18Z" />
    </g>
  </svg>
);

export default ChevronDown;
