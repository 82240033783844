import {
  ChoiceInput,
  MultipleChoiceQuestion,
} from '../../../models/Question.interface';
import OptionImg from './OptionImg';
import { useEffect, useState } from 'react';
import QuestionTitle from './QuestionTitle';
import RadioCircle from './RadioCircle';
import Modal from 'react-modal';
import Button from '../../Button';

export default function MultiChoice(props: {
  title?: string;
  quesNo?: number;
  choices: ChoiceInput[];
  placeholder?: string;
  accId?: string;
  orderFormId: string;
  value?: any;
  handleValue: (index: any, newValues: any) => void;
  question: MultipleChoiceQuestion;
  invalid?: string[];
}) {
  const {
    quesNo,
    title,
    choices,
    accId,
    orderFormId,
    value,
    question,
    handleValue,
    invalid,
  } = props;

  const [imageModal, setImageModal] = useState('');
  const [dirty, setDirty] = useState(false);

  // on invalid update dirty
  useEffect(() => {
    setDirty(false);
  }, [invalid]);

  return (
    <div className="mt-8">
      <QuestionTitle quesNo={quesNo} title={title} />
      <div className="grid grid-cols-1 md:grid-cols-2">
        {choices?.map((choice, index) => {
          return (
            <div
              key={index}
              onClick={(element) => {
                console.log(element);
                console.log((element.target as any).className);

                if ((element.target as any).className !== 'view-full-image') {
                  const updatedChoices = question?.choiceAnswers?.slice() || [];
                  const isFound = question.choiceAnswers?.find(
                    (e) => e.title === choice.title
                  )
                    ? true
                    : false;
                  if (isFound) {
                    updatedChoices?.splice(
                      updatedChoices?.findIndex(
                        (e: any) => e?.title === choice.title
                      ),
                      1
                    );
                  } else {
                    updatedChoices.push({
                      title: choice.title,
                      subtitle: '',
                      photoRef: '',
                    });
                  }

                  if (question.singleSelection) {
                    handleChange(
                      [
                        {
                          title: choice.title,
                          subtitle: '',
                          photoRef: '',
                        },
                      ],
                      'choiceAnswers'
                    );
                  } else {
                    handleChange(updatedChoices, 'choiceAnswers');
                  }
                }
              }}
            >
              <div className={`bg-white flex items-center pl-4 cursor-pointer`}>
                <RadioCircle
                  selected={
                    question.choiceAnswers?.find(
                      (e: { title: string }) => e.title === choice.title
                    )
                      ? true
                      : false
                  }
                />
                <div
                  className={`short_text_input ${
                    !!invalid && !dirty && 'invalid'
                  }`}
                >
                  {choice.title}
                </div>
              </div>
              {choice?.photoRef && (
                <>
                  <div className="my-2">
                    <OptionImg
                      photo={choice?.photoRef}
                      accId={accId || ''}
                      orderFormId={orderFormId}
                    />
                    <div
                      className="view-full-image"
                      style={{ cursor: 'pointer', textAlign: 'center' }}
                      onClick={() => setImageModal(choice.photoRef || '')}
                    >
                      View full image
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      {!!imageModal && (
        <Modal
          isOpen={true}
          onRequestClose={() => setImageModal('')}
          shouldCloseOnOverlayClick
          style={{
            content: {
              border: '0',
              borderRadius: '4px',
              bottom: 'auto',
              minHeight: '10rem',
              left: '50%',
              padding: '2rem',
              position: 'fixed',
              right: 'auto',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              minWidth: '20rem',
              maxWidth: '60rem',
            },
          }}
        >
          <OptionImg
            photo={imageModal}
            accId={accId || ''}
            orderFormId={orderFormId}
            fullImage
          />
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button onClick={() => setImageModal('')} title="CLOSE" />
          </div>
        </Modal>
      )}
    </div>
  );
  function handleChange(value: string | boolean | any[], field: string) {
    if (!dirty) {
      setDirty(true);
    }

    let newValues = {
      ...question,
      [field]: value,
    };
    handleValue(quesNo ? quesNo - 1 : 0, newValues);
  }
}
