import * as React from 'react';
import colors from '../../constants/colors';

function Edit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 16 16"
      fill={colors.primary.color1}
      height={21}
      width={21}
      {...props}
    >
      <path d="M15.81 3L13.31.51a.65.65 0 00-.93 0L4.73 8.15a.7.7 0 00-.19.42l-.2 2.76A.67.67 0 005 12h.06l2.7-.27a.63.63 0 00.4-.19l7.65-7.65a.66.66 0 000-.93zm-8.42 7.48l-1.68.17.13-1.73 4.79-4.79 1.56 1.56zm5.73-5.73l-1.56-1.56 1.29-1.28 1.56 1.56z" />
      <path d="M10.48 16H3.63A3.64 3.64 0 010 12.37V5.52a3.64 3.64 0 013.63-3.63h3.09a.66.66 0 110 1.32H3.63a2.32 2.32 0 00-2.31 2.31v6.85a2.32 2.32 0 002.31 2.31h6.85a2.32 2.32 0 002.31-2.31v-2.7a.66.66 0 111.32 0v2.7A3.64 3.64 0 0110.48 16z" />
    </svg>
  );
}

export default Edit;
