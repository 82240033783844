import { AppConfig } from '../constants/aws-config';
import { UPLOAD_PHOTO } from '../constants/config';

export async function getPhotoUrl(props: {
  photoRef: string;
  orderId: string;
  type?: string;
  path?: string;
  folder?: string;
  isAccountPhoto?: boolean;
  folderId: string;
}) {
  const { photoRef, orderId, type, path, folder, isAccountPhoto, folderId } =
    props;

  // covers case where the photoUrl is the default just ensure it returns as is
  // note there's probably a better way to do this but it requires a bit to rework
  if (photoRef === getDefaultPhotoUrl()) {
    return { ['uri']: photoRef };
  }

  const currentFolder = folder || 'accounts';
  const currentPath = path || 'banner';
  const GET_S3_URL = AppConfig.photoConfig.photoUrl;
  const ImageURI = isAccountPhoto
    ? `${GET_S3_URL}/${currentFolder}/${folderId}/${photoRef}.jpeg`
    : `${GET_S3_URL}/${currentFolder}/${folderId}/order/${orderId}/${currentPath}/${photoRef}.jpeg`;
  let typeVar = 'uri';

  if (type) {
    typeVar = type;
  }
  return {
    [typeVar]: ImageURI,
  };
}

export const getDefaultPhotoUrl = () => {
  const GET_S3_URL = AppConfig.photoConfig.photoUrl;
  return `${GET_S3_URL}/default/cloned_image_placeholder.png`;
};

export const uploadPhotoToS3 = async (body: any) => {
  const UPLOAD_S3_URL = AppConfig.photoConfig.uploadS3Url + UPLOAD_PHOTO;
  const xApiKey = AppConfig.apiKey.uploadKey;

  return fetch(UPLOAD_S3_URL, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': xApiKey,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      // error occured while upload place photo
    });
};
