export default function Spinner(props: { style?: any }) {
  return (
    <div className={`lds-spinner`} style={props.style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
