export default function SocialHandles(props: {
  facebook: string;
  instagram: string;
}) {
  const { facebook, instagram } = props;
  return (
    <div className="flex items-center justify-center -mt-6">
      <div className="grid grid-cols-2 gap-1">
        {facebook && (
          <div
            className="social_thankyou_icon"
            onClick={() => {
              window.open(facebook);
            }}
          >
            <i className="fab fa-facebook text-clifford"></i>
          </div>
        )}
        {instagram && (
          <div
            className="social_thankyou_icon"
            onClick={() => {
              window.open(`${instagram.startsWith("http") ? instagram : `https://www.instagram.com/${instagram}/`}`);
            }}
          >
            <i className="fab fa-instagram text-clifford"></i>
          </div>
        )}
      </div>
    </div>
  );
}
