import { gql } from '@apollo/client';

const getOrderFormBySlug = () => gql`
  query getOrderFormBySlug($slug: String!) {
    getOrderFormBySlug(slug: $slug) {
      items {
        orderFormId
        accountId
        orderStatus
        bannerPhotoRef
        availability {
          startDate
          endDate
        }
        questions {
          ... on ShortAnswer {
            placeholder
            required
            title
            shortAnswerType
            questionType
            precedence
            answer
            systemQuestion
          }
          ... on Dropdown {
            required
            singleSelection
            choiceType
            precedence
            questionType
            choiceAnswers {
              photoRef
              subtitle
              title
            }
            placeholder
            choices {
              photoRef
              subtitle
              title
            }
            title
            systemQuestion
          }
          ... on Choice {
            required
            singleSelection
            answered
            choiceType
            choices {
              photoRef
              subtitle
              title
            }
            choiceAnswers {
              photoRef
              subtitle
              title
            }
            placeholder
            precedence
            questionType
            title
            systemQuestion
          }
          ... on DateSelection {
            answer
            required
            answered
            precedence
            questionType
            time
            title
            systemQuestion
          }
          ... on Paragraph {
            placeholder
            required
            answer
            answered
            precedence
            questionType
            title
            systemQuestion
          }
          ... on FileUpload {
            required
            answer
            answered
            precedence
            questionType
            title
          }
        }
        slug
        subtitle
        title
        description
      }
    }
  }
`;

const getAccountPublic = () => gql`
  query getAccountPublic($accountId: ID!) {
    getAccountPublic(accountId: $accountId) {
      accountId
      availability {
        endDate
        createdOn
        notes
        startDate
        userId
      }
      company {
        logoRef
        name
        website
        supportEmail
        facebook
        instagram
      }
    }
  }
`;

const listAllUsersByAccount = () => gql`
  query listAllUsersByAccount($accountId: ID!) {
    listAllUsersByAccount(accountId: $accountId) {
      items {
        accountId
        defaultPhotoRefId
      }
    }
  }
`;

const createInquiryPipeline = () => gql`
  mutation createInquiryPipeline($input: CreateInquiryInput!) {
    createInquiryPipeline(input: $input) {
      accountId
      inquiryId
    }
  }
`;

const getUserAccess = () => gql`
  query getAccountSubscriptionFromAccountId($accountId: ID!) {
    getAccountSubscriptionFromAccountId(accountId: $accountId) {
      items {
        accountId
        accountSubscriptionId
        hasAccess
      }
    }
  }
`;

export {
  getOrderFormBySlug,
  getAccountPublic,
  createInquiryPipeline,
  getUserAccess,
  listAllUsersByAccount,
};
