import { useEffect, useRef, useState } from 'react';
import { getPhotoUrl } from '../utils/photo';
import FormSample from '../assets/images/formSample.jpeg';

export default function Banner(props: {
  banner: string;
  orderFormId: string;
  accId: string;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { banner, orderFormId, accId, setIsLoading } = props;

  const [uri, setUri] = useState<string>(FormSample);

  const bannerTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  async function getBannerPhoto() {
    try {
      const url = await getPhotoUrl({
        photoRef: banner,
        orderId: orderFormId,
        type: undefined,
        folderId: accId,
      });
      if (url.uri) {
        setUri(url.uri);
      }
    } catch (error) {
      if (!!setIsLoading) setIsLoading(false);
    }
  }

  const imageFullyLoaded = (props: any) => {
    const targetUrl: string = props?.target?.src || '';

    if (!!setIsLoading && targetUrl.includes(FormSample)) {
      if (bannerTimeoutRef?.current) {
        clearTimeout(bannerTimeoutRef.current);
      }
      bannerTimeoutRef.current = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }

    if (!!setIsLoading && !targetUrl.includes(FormSample)) {
      if (bannerTimeoutRef?.current) {
        clearTimeout(bannerTimeoutRef.current);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (banner && orderFormId && accId) {
      getBannerPhoto();
    }

    // if everything is loaded in but the banner is still not there just setLoading to false
    if (!banner && orderFormId && accId) {
      if (!!setIsLoading) setIsLoading(false);
    }
  }, [banner, orderFormId, accId]);

  return (
    <div className="w-full">
      <img
        src={uri}
        className="object-cover banner w-full"
        alt="banner"
        onLoad={imageFullyLoaded}
        onError={() => {
          setUri(FormSample);
        }}
      />
    </div>
  );
}
