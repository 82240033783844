const colors = {
  primary: {
    color1: '#7C8B9C',
    color2: '#C9D6DD',
    color3: '#FC0981',
    color4: '#7E8F9E',
    color5: '#FF0081',
    color6: '#616D7B'
  },
  secondary: {
    color1: '#6B6771',
    color2: '#EABFBD',
    color3: '#6D6671',
    color4: '#C4D2DA',
    color5: '#EDEDED',
    color6: '#88868C',
  },
  tertiary: {
    color1: '#E6E6E6',
    color2: '#CCCDD7',
    color3: '#808091',
    color4: '#707070',
    color5: '#475C74',
    color6: '#B2C6DD'
  },
  white: '#FFFFFF',
  black: '#000000',
  lightBack: '#FCFCFC',
  iconColor: '#8A878E',
  otpBorder: '#7070704D',
  transparent: 'transparent',
  lightShade2: '#F4F4F4',
  link: '#2454F4B3',
};

export default colors;
