import Logo from '../assets/images/logo-white.png';

export default function Header(props: {
  isProUser: boolean;
  informationalPage?: boolean;
}) {
  return (
    <div
      className={`bg-clifford flex items-center justify-center md:${
        props.informationalPage ? 'pt-8 pb-8' : 'pt-20 pb-36'
      }`}
    >
      {!props.isProUser && <img src={Logo} className="h-32" alt="logo" />}
    </div>
  );
}
