import { useEffect, useState } from 'react';

export default function QuestionTitle(props: {
  quesNo?: number;
  title?: string;
}) {
  const { quesNo, title } = props;

  const [titleWithBreaks, setTitleWithBreaks] = useState<any>('');

  useEffect(() => {
    if (title) {
      setTitleWithBreaks(
        title.split('\n').map((str: string) => (
          <span key={str} className="questionBreak">
            {str}
          </span>
        ))
      );
    }
  }, []);

  return (
    <>
      <div className="text-robin font-14 font-serif question-number">
        {quesNo}.
      </div>
      <div className="text-robin font-14 font-serif question-title">
        {titleWithBreaks}
      </div>
    </>
  );
}
