import * as React from 'react';
import { SVGProps } from 'react';
import colors from '../../constants/colors';

function ImageIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 16 16"
      fill={colors.secondary.color1}
      height={34}
      width={34}
      {...props}
    >
      <path d="M12.67 0H3.33A3.33 3.33 0 000 3.33v9.34A3.33 3.33 0 003.33 16h9.34A3.33 3.33 0 0016 12.67V3.33A3.33 3.33 0 0012.67 0zM3.33 1.33h9.34a2 2 0 012 2v9.34a1.93 1.93 0 01-.2.85L8.36 7.41a3.34 3.34 0 00-4.72 0L1.33 9.72V3.33a2 2 0 012-2zm0 13.34a2 2 0 01-2-2v-1.06l3.26-3.25a2 2 0 012.82 0l6.11 6.11a1.93 1.93 0 01-.85.2z" />
      <path d="M10.67 7a2.34 2.34 0 10-2.34-2.33A2.33 2.33 0 0010.67 7zm0-3.33a1 1 0 11-1 1 1 1 0 011-1z" />
    </svg>
  );
}

export default ImageIcon;
