export enum Environments {
  DEV = 'dev',
  PROD = 'prod',
}

export enum AuthenticationFlowType {
  USER_SRP_AUTH = 'CUSTOM_AUTH',
}

export enum AwsRegion {
  US_EAST_1 = 'us-east-1',
}

export enum AwsAppSyncAuthenticationType {
  COGNITO = 'API_KEY',
}

export interface AmplifyConfig {
  userPoolId: string;
  userPoolWebClientId: string;
  region: AwsRegion;
  mandatorySignIn: boolean;
  authenticationFlowType: AuthenticationFlowType;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: AwsAppSyncAuthenticationType;
  aws_appsync_apiKey: string;
}

export interface ApiEnv {
  apiKey: string;
  uploadKey: string;
}

export interface PhotoConfig {
  photoUrl: string;
  uploadS3Url: string;
  attachmentS3Url: string;
  getS3Url: string;
}

const DevPhotoConfig: PhotoConfig = {
  photoUrl: 'https://sift-photos-development.s3.amazonaws.com',
  uploadS3Url:
    'https://0snhe7rxp2.execute-api.us-east-1.amazonaws.com/Development/api/',
  attachmentS3Url:
    'https://ycb5z9quni.execute-api.us-east-1.amazonaws.com/Beta/api/',
  getS3Url:
    'https://0snhe7rxp2.execute-api.us-east-1.amazonaws.com/Development/api/get-sift-photo',
};

const ProdPhotoConfig: PhotoConfig = {
  photoUrl: 'https://sift-photos-prod.s3.amazonaws.com',
  attachmentS3Url:
    'https://wwpt21lca9.execute-api.us-east-1.amazonaws.com/Prod/api/',
  uploadS3Url:
    'https://y26w0ks9dl.execute-api.us-east-1.amazonaws.com/Prod/api/',
  getS3Url:
    'https://y26w0ks9dl.execute-api.us-east-1.amazonaws.com/Prod/api/get-sift-photo',
};

const DevApiKey: ApiEnv = {
  apiKey: '5lbjlmj2mfavxds2eyjcfdyvni',
  uploadKey: 'XH9cWA7mBF2woJZVwTCk1aLcmS0BkRKra3IuPwSr',
};

const ProdApiKey: ApiEnv = {
  apiKey: '34m2u72hjffzdoemdxeui4q4fm',
  uploadKey: 'u7ixJvryGR4j9hw9Ux62O1WXkol1bukd4oCUn7OI',
};

const DevAmplifyConfig: AmplifyConfig = {
  userPoolId: 'us-east-1_W8Txv7U0t',
  userPoolWebClientId: '1f8cv2j1jn1j671dlpqjcnncdn',
  region: AwsRegion.US_EAST_1,
  mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  aws_appsync_graphqlEndpoint:
    'https://apo7gk45lzcrpmllxinazz23uy.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: AwsRegion.US_EAST_1,
  aws_appsync_authenticationType: AwsAppSyncAuthenticationType.COGNITO, // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  authenticationFlowType: AuthenticationFlowType.USER_SRP_AUTH,
  aws_appsync_apiKey: `da2-${DevApiKey.apiKey}`,
};

const ProdAmplifyConfig: AmplifyConfig = {
  userPoolId: 'us-east-1_A4zcIUsMc',
  userPoolWebClientId: '34epuu7ndpfimmf6qeic0u1lo1',
  region: AwsRegion.US_EAST_1,
  mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  aws_appsync_graphqlEndpoint:
    'https://txzywmnsczasnlnzucdbxzi4my.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: AwsRegion.US_EAST_1,
  aws_appsync_authenticationType: AwsAppSyncAuthenticationType.COGNITO, // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  authenticationFlowType: AuthenticationFlowType.USER_SRP_AUTH,
  aws_appsync_apiKey: `da2-${ProdApiKey.apiKey}`,
};

export interface EnvironmentInterface {
  env: Environments;
  amplifyConfig: AmplifyConfig;
  photoConfig: PhotoConfig;
}
let instance: Environment;
export class Environment implements EnvironmentInterface {
  readonly env: Environments;
  readonly amplifyConfig: AmplifyConfig;
  readonly photoConfig: PhotoConfig;
  readonly apiKey: ApiEnv;

  constructor() {
    this.env = this.getCurrentEnv();
    this.amplifyConfig = this.getAmplifyConfig(this.env);
    this.photoConfig = this.getPhotoConfig(this.env);
    this.apiKey = this.getApiKey(this.env);
    instance = this;
  }

  getCurrentEnv() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      return Environments.PROD;
    } else return Environments.DEV;
  }

  getPhotoConfig(env: Environments): PhotoConfig {
    return {
      photoUrl:
        env === Environments.PROD
          ? ProdPhotoConfig.photoUrl
          : DevPhotoConfig.photoUrl,
      attachmentS3Url:
        env === Environments.PROD
          ? ProdPhotoConfig.attachmentS3Url
          : DevPhotoConfig.attachmentS3Url,
      uploadS3Url:
        env === Environments.PROD
          ? ProdPhotoConfig.uploadS3Url
          : DevPhotoConfig.uploadS3Url,
      getS3Url:
        env === Environments.PROD
          ? ProdPhotoConfig.getS3Url
          : DevPhotoConfig.getS3Url,
    };
  }

  getApiKey(env: Environments): ApiEnv {
    return {
      apiKey: env === Environments.PROD ? DevApiKey.apiKey : ProdApiKey.apiKey,
      uploadKey:
        env === Environments.PROD ? ProdApiKey.uploadKey : DevApiKey.uploadKey,
    };
  }

  getAmplifyConfig(env: Environments): AmplifyConfig {
    return {
      userPoolId:
        env === Environments.PROD
          ? ProdAmplifyConfig.userPoolId
          : DevAmplifyConfig.userPoolId,
      userPoolWebClientId:
        env === Environments.PROD
          ? ProdAmplifyConfig.userPoolWebClientId
          : DevAmplifyConfig.userPoolWebClientId,
      region:
        env === Environments.PROD
          ? ProdAmplifyConfig.region
          : DevAmplifyConfig.region,
      mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      aws_appsync_graphqlEndpoint:
        env === Environments.PROD
          ? ProdAmplifyConfig.aws_appsync_graphqlEndpoint
          : DevAmplifyConfig.aws_appsync_graphqlEndpoint,
      aws_appsync_region: AwsRegion.US_EAST_1,
      aws_appsync_authenticationType: AwsAppSyncAuthenticationType.COGNITO, // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
      aws_appsync_apiKey:
        env === Environments.PROD
          ? `da2-${ProdApiKey.apiKey}`
          : `da2-${DevApiKey.apiKey}`,
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      // domain: '.yourdomain.com',
      // OPTIONAL - Cookie path
      // path: '/',
      // OPTIONAL - Cookie expiration in days
      // expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      // secure: true
      // },
      // OPTIONAL - customized storage object
      // storage: MyStorage,
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: AuthenticationFlowType.USER_SRP_AUTH,
      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },
      // OPTIONAL - Hosted UI configuration
      // oauth: {
      // domain: 'your_cognito_domain',
      // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // redirectSignIn: 'http://localhost:3000/',
      // redirectSignOut: 'http://localhost:3000/',
      // responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      // }
    };
  }
}

export const AppConfig = new Environment();
