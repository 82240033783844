import Home from '../pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Thankyou from '../pages/Thankyou';
import FormNotAvailable from '../pages/FormNotAvailable';
import DeletionInformation from '../pages/DeletionInformation';

export default function AppRoute() {
  return (
    <Router>
      <Routes>
        <Route path="/notavailable/:slug" element={<FormNotAvailable />} />
        <Route path="/thankyou/:slug" element={<Thankyou />} />
        <Route path="/order/:slug" element={<Home />} />
        <Route path="/deletion" element={<DeletionInformation />} />
      </Routes>
    </Router>
  );
}
