import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Banner from '../components/Banner';
import Layout from '../components/Layout';
import ProfileImg from '../components/ProfileImg';
import {
  getAccountPublic,
  getOrderFormBySlug,
  getUserAccess,
  listAllUsersByAccount,
} from '../constants/graphql';
import API, { graphqlOperation } from '@aws-amplify/api';
import RedirectText from '../components/RedirectText';
import SocialHandles from '../components/SocialHandles';
import HomeSpinner from '../components/HomeSpinner';

export default function Thankyou() {
  const params = useParams();
  const location: any = useLocation();

  const [acc, setAccDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bannerRef, setBannerRef] = useState<string>('');
  const [logoRef, setLogoRef] = useState<string>('');
  const [accId, setAccId] = useState<string>('');
  const [orderFormId, setOrderFormId] = useState<string>('');
  const [isProUser, setIsProUser] = useState<boolean>(false);

  const layoutClass =
    'container mx-auto mx-auto flex flex-col shadow-lg -mt-32 rounded-2xl overflow-hidden mb-16';
  const conditionalLayoutClass = `${layoutClass} ${isLoading ? 'hidden' : ''}`;

  async function getOrderForm(slug: string) {
    try {
      setIsLoading(true);
      const orderForm: any = await API.graphql(
        graphqlOperation(getOrderFormBySlug(), { slug })
      );
      const accInfo = orderForm?.data?.getOrderFormBySlug?.items;
      if (accInfo && accInfo?.length > 0) {
        const accId = accInfo[0].accountId; // * ACC Id
        setAccId(accId);
        const accRes = (await API.graphql(
          graphqlOperation(getAccountPublic(), { accountId: accId })
        )) as { data: any };
        // setIsLoading(false);
        setAccDetails(accRes?.data?.getAccountPublic?.company || {});

        let profilePhoto = accRes?.data?.getAccountPublic?.company?.logoRef;
        if (!!profilePhoto) {
          setLogoRef(profilePhoto);
        } else {
          // Since users and companies are 1:1 in this case we can safely assume that the first returned will be the one to display
          // I fought with queries for way too long and settled on this one, if you can make one that just grabs defaultPhotoRefId without using this call that'd be ideal
          const profilePhotoRes: any = await API.graphql(
            graphqlOperation(listAllUsersByAccount(), {
              accountId: accId,
            })
          );
          setLogoRef(
            profilePhotoRes?.data?.listAllUsersByAccount?.items[0]
              ?.defaultPhotoRefId || ''
          );
        }

        const questionsItems = orderForm?.data?.getOrderFormBySlug?.items;
        if (questionsItems && questionsItems?.length > 0) {
          setBannerRef(questionsItems[0].bannerPhotoRef || '');
          setOrderFormId(questionsItems[0].orderFormId);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (params.slug) {
      getOrderForm(params.slug);
    }
  }, [params]);

  useEffect(() => {
    if (!!accId) {
      checkUserStatus();
    }
  }, [accId]);

  async function checkUserStatus() {
    try {
      const isProUserReturn = (await API.graphql(
        graphqlOperation(getUserAccess(), { accountId: accId })
      )) as { data: any };

      if (
        isProUserReturn?.data?.getAccountSubscriptionFromAccountId?.items[0]
          ?.hasAccess
      ) {
        setIsProUser(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Layout isProUser={isProUser} isLoading={isLoading}>
      <>
        <div className={conditionalLayoutClass}>
          <Banner
            banner={bannerRef}
            accId={accId}
            orderFormId={orderFormId}
            setIsLoading={setIsLoading}
          />
          {logoRef && (
            <div className="w-full">
              <div className="product_img bg-gray flex items-center justify-center rounded-full mx-auto -mt-16 overflow-hidden shadow-xl">
                <ProfileImg
                  banner={logoRef}
                  accId={accId}
                  companyName={acc.name}
                />
              </div>
            </div>
          )}
          <div className="pb-8 w-3/4 lg:w-2/4 mx-auto mt-4">
            <div className="w-full">
              <div className="flex items-center justify-center">
                <div className="text-5xl mx-auto text-gray font-serif">
                  Thank You!
                </div>
              </div>
              <div className="grid grid-cols-1 p-2 mt-4">
                <div className="thankyou_description">
                  <span>We've got your inquiry,</span>
                </div>
                <div className="thankyou_description">
                  <span>and we'll get back to you</span>
                </div>
                <div className="thankyou_description">
                  <span>as soon as we can</span>
                </div>
                <div className="mx-auto font-roboto mt-4 text-lg md:text-xl font-light text-center">
                  <span>
                    Your reference ID is #{location?.state?.inqId || ''}
                  </span>
                </div>
                {(acc?.facebook || acc?.instagram) && (
                  <div className="mx-auto font-serif font-m-1 text-light p-8">
                    <span className="text-robin font-serif text-2xl">
                      Follow us!
                    </span>
                  </div>
                )}
                <SocialHandles
                  facebook={acc.facebook}
                  instagram={acc.instagram}
                />
                <div className="mt-12 mx-auto">
                  {acc?.supportEmail && (
                    <RedirectText
                      title={acc?.supportEmail}
                      onClick={() => {
                        window.open(`mailto://${acc?.supportEmail}`);
                      }}
                    />
                  )}
                  {acc?.website && (
                    <RedirectText
                      title={acc?.website}
                      onClick={() => {
                        window.open(acc?.website);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeSpinner active={isLoading} />
      </>
    </Layout>
  );
}
