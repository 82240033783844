import type { FileUpload as FileUploadType } from '../../../models/Question.interface';
import ImageIcon from '../../Icons/ImageIcon';
import QuestionTitle from './QuestionTitle';
import { Fragment, useEffect, useRef, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import { Cropper } from 'react-cropper';
import Modal from 'react-modal';
import Button from '../../Button';
import { convertBase64ToMd5Hash } from '../../../utils/common';
import { uploadPhotoToS3 } from '../../../utils/photo';
import Edit from '../../Icons/Edit';
import colors from '../../../constants/colors';
import Spinner from '../../Spinner';
import heic2any from 'heic2any';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
  },
};
export default function FileUpload(props: {
  title?: string;
  quesNo?: number;
  question: FileUploadType;
  accId: string;
  orderFormId: string;
  handleValue: (index: any, newValues: any) => void;
  invalid?: string[];
}) {
  const { handleValue, quesNo, title, question, accId, orderFormId, invalid } =
    props;

  const [tempUri, setTempUri] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [croppedImg, setCroppedImg] = useState<string>('');
  const [isHeic, setIsHeic] = useState<boolean>(false);

  const cropper = useRef<any>();

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [dirty, setDirty] = useState(false);

  // on invalid update dirty
  useEffect(() => {
    setDirty(false);
  }, [invalid]);

  function closeModal() {
    setModalIsOpen(false);
    setTempUri(undefined);
  }

  function handleSave() {
    const croppedImgData = cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL('image/jpeg', 0.9);

    setModalIsOpen(false);
    uploadBase64(croppedImgData as string);
  }

  async function uploadBase64(base64: string | undefined) {
    try {
      if (base64) {
        const hash = convertBase64ToMd5Hash(base64);
        const body: any = {
          photoBase64: base64,
          hashOfPhoto: hash,
          folder: `accounts/${accId}/${orderFormId}`,
          bucket: 'sift-inquiry',
        };
        setIsLoading(true);
        const result = await uploadPhotoToS3(body);
        setIsLoading(false);
        if (result?.hashOfPhoto) {
          setCroppedImg(base64);
          handleChange(result?.hashOfPhoto, 'answer');
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  function blobToBase64(blob: Blob) {
    // * function to convert blob to base64
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result;
      // * got the base 64 result, setting the base64 string in state
      setTempUri(base64data);
    };
  }

  function convertHeicToJpeg(objUrl: string) {
    fetch(objUrl)
      .then((res) => res.blob())
      .then((blob) => heic2any({ blob }))
      .then((conversionResult) => {
        // * converted to png but result is in blob
        // * converting blob result to base 64
        blobToBase64(conversionResult as Blob);
      })
      .catch((e) => {
        // see error handling section
      });
  }

  function HiddenInput() {
    return (
      <input
        id={`file-upload-${quesNo ? quesNo - 1 : 0}`}
        className="hidden"
        type="file"
        onChange={(e) => {
          if (e.target.files && e.target.files?.length > 0) {
            const imageName = e.target.files[0].name;
            if (imageName?.toLowerCase().includes('.heic')) {
              // * maintaing a flag for heic
              setIsHeic(true);
              const objUrl = URL.createObjectURL(e.target.files[0]);
              // * convert heic to jepg or png format
              convertHeicToJpeg(objUrl);
              setModalIsOpen(true);
            } else {
              // * maintaing a flag for heic
              setIsHeic(false);
              setTempUri(e.target.files[0]);
              setModalIsOpen(true);
            }
          }
        }}
      />
    );
  }

  Modal.setAppElement('#root');

  return (
    <div className="mt-8">
      <QuestionTitle quesNo={quesNo} title={title} />
      <div className="mt-6 overflow-hidden flex w-full justify-center">
        {isLoading ? <Spinner /> : null}
      </div>
      {croppedImg ? (
        <div className="mt-6 flex w-full justify-center">
          <div className="relative">
            <img
              src={croppedImg}
              alt="cropped-img"
              className="rounded-xl"
              style={{
                maxHeight: '1000px',
              }}
            />
            <label
              htmlFor={`file-upload-${quesNo ? quesNo - 1 : 0}`}
              className="cursor-pointer bg-gray p-2 rounded-3xl absolute"
              style={{
                bottom: '-10px',
                right: '-10px',
              }}
            >
              <Edit fill={colors.white} />
              <HiddenInput />
            </label>
          </div>
        </div>
      ) : (
        <Fragment>
          {!isLoading ? (
            <>
              <label
                className={`mt-4 cursor-pointer box_shadow_input bg-white py-6 flex flex-col items-center justify-center ${
                  !!invalid && !dirty && 'invalid'
                }`}
                htmlFor={`file-upload-${quesNo ? quesNo - 1 : 0}`}
              >
                <ImageIcon />
                <div className="mt-4 font-roboto italic text-sm">
                  Click Here to Upload Image
                </div>
              </label>
              <HiddenInput />
            </>
          ) : null}
        </Fragment>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
      >
        <Cropper
          style={{ maxWidth: '600px', height: '400px' }}
          ref={cropper}
          src={tempUri && !isHeic ? URL.createObjectURL(tempUri) : tempUri}
          cropend={() => {}}
        />
        <div className="w-full items-center mt-6 justify-center flex modal-buttons">
          <Button onClick={closeModal} title="CLOSE" />
          <Button onClick={handleSave} title="SAVE" loading={isLoading} />
        </div>
      </Modal>
    </div>
  );

  function handleChange(value: string | boolean | any[], field: string) {
    if (!dirty) {
      setDirty(true);
    }

    let newValues = {
      ...question,
      [field]: value,
    };
    handleValue(quesNo ? quesNo - 1 : 0, newValues);
  }
}
