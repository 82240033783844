import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Banner from '../components/Banner';
import Layout from '../components/Layout';
import ProfileImg from '../components/ProfileImg';
import {
  getAccountPublic,
  getOrderFormBySlug,
  getUserAccess,
} from '../constants/graphql';
import API, { graphqlOperation } from '@aws-amplify/api';
import WarningSign from '../assets/images/esclamation-mark.png';
import RedirectText from '../components/RedirectText';
import SocialHandles from '../components/SocialHandles';
import HomeSpinner from '../components/HomeSpinner';

export default function FormNotAvailable() {
  const params = useParams();

  const [acc, setAccDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accId, setAccId] = useState<string>('');
  const [logoRef, setLogoRef] = useState<string>('');
  const [bannerRef, setBannerRef] = useState<string>('');
  const [orderFormId, setOrderFormId] = useState<string>('');
  const [isProUser, setIsProUser] = useState<boolean>(false);

  async function getOrderForm(slug: string) {
    try {
      setIsLoading(true);
      const orderForm: any = await API.graphql(
        graphqlOperation(getOrderFormBySlug(), { slug })
      );
      const accInfo = orderForm?.data?.getOrderFormBySlug?.items;
      if (accInfo && accInfo?.length > 0) {
        const accId = accInfo[0].accountId; // * ACC Id
        setAccId(accId);
        const accRes = (await API.graphql(
          graphqlOperation(getAccountPublic(), { accountId: accId })
        )) as { data: any };
        setAccDetails(accRes?.data?.getAccountPublic?.company || {});
        setLogoRef(accRes?.data?.getAccountPublic?.company?.logoRef || '');
        const questionsItems = orderForm?.data?.getOrderFormBySlug?.items;
        if (questionsItems && questionsItems?.length > 0) {
          setBannerRef(questionsItems[0].bannerPhotoRef || '');
          setOrderFormId(questionsItems[0].orderFormId);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (params.slug) {
      getOrderForm(params.slug);
    }
  }, [params]);

  useEffect(() => {
    checkUserStatus();
  }, [accId]);

  async function checkUserStatus() {
    try {
      const isProUserReturn = (await API.graphql(
        graphqlOperation(getUserAccess(), { accountId: accId })
      )) as { data: any };

      if (
        isProUserReturn?.data?.getAccountSubscriptionFromAccountId?.items[0]
          ?.hasAccess
      ) {
        setIsProUser(true);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  return (
    <Layout isLoading={isLoading} isProUser={isProUser}>
      <div className="container mx-auto mx-auto flex flex-col shadow-lg -mt-32 rounded-2xl overflow-hidden mb-16">
        <Banner
          banner={bannerRef || ''}
          accId={accId || ''}
          orderFormId={orderFormId || ''}
        />
        {logoRef && (
          <div className="w-full">
            <div className="product_img bg-gray flex items-center justify-center rounded-full mx-auto -mt-16 overflow-hidden shadow-xl">
              <ProfileImg
                banner={logoRef}
                accId={accId || ''}
                companyName={acc.name}
              />
            </div>
          </div>
        )}

        <div className="pb-8 w-3/4 lg:w-2/4 mx-auto mt-4">
          <div className="w-full">
            <div className="grid grid-cols-1 p-2 mt-4">
              <div className="flex items-center justify-center flex-col">
                <div className="warning_box flex items-center justify-center">
                  <img
                    src={WarningSign}
                    className="object-cover"
                    alt="warning_sign"
                  />
                </div>
                <div className="box text-center">
                  <h1 className="text-3xl md:text-4xl text-gray underline font-serif">
                    Important Notice:
                  </h1>
                </div>
                <div className="text-center flex flex-col text-robin font-serif">
                  <h2 className="text-2xl md:text-3xl font-serif text-robin mt-4">
                    This Form is no longer available.
                  </h2>
                  <p className="text-lg md:text-xl max-w-sm mx-auto">
                    Please check with the person who shared it with you to see
                    if there's a new form available
                  </p>
                  <p className="text-lg md:text-xl px-8 mt-4">
                    For updates, specials and more
                  </p>
                </div>
              </div>

              {(acc?.facebook || acc?.instagram) && (
                <div className="mx-auto font-serif font-m-1 text-light p-8 pt-0">
                  <span className="text-robin font-serif text-xl md:text-2xl">
                    Follow us!
                  </span>
                </div>
              )}
              <SocialHandles
                facebook={acc.facebook}
                instagram={acc.instagram}
              />
              <div className="mt-12 mx-auto">
                {acc?.supportEmail && (
                  <RedirectText
                    title={acc?.supportEmail}
                    onClick={() => {
                      window.open(`mailto://${acc?.supportEmail}`);
                    }}
                  />
                )}
                {acc?.website && (
                  <RedirectText
                    title={acc?.website}
                    onClick={() => {
                      window.open(acc?.website);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <HomeSpinner active={isLoading} />
      </div>
    </Layout>
  );
}
