import { useEffect, useState } from 'react';
import { ShortAnswerQuestion } from '../../../models/Question.interface';
import QuestionTitle from './QuestionTitle';

interface fieldsInterface
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  containerClassName?: string;
  containerStyles?: React.CSSProperties;
}

export default function ShortText(props: {
  fields?: fieldsInterface[];
  title?: string;
  quesNo?: number;
  question?: ShortAnswerQuestion;
  handleValue: (index: any, newValues: any) => void;
  invalid?: string[];
}) {
  const { fields = [], title, quesNo, question, handleValue, invalid } = props;

  const [dirty, setDirty] = useState(false);

  // on invalid update dirty
  useEffect(() => {
    setDirty(false);
  }, [invalid]);

  return (
    <div className="mt-8">
      <QuestionTitle quesNo={quesNo} title={title} />
      <div className={`flex flex-wrap`}>
        {fields &&
          fields?.map((field, index) => {
            const { containerClassName, ...allFieldAttr } = field;
            const disallowedCharacters = /[~`@#$^&(){}[\]:;,<>\/]/;

            return (
              <div
                className={`mt-4 box_shadow_input bg-white ${containerClassName}`}
                style={field.containerStyles}
                key={index}
              >
                <input
                  className={`short_text_input ${
                    !!invalid && !dirty && 'invalid'
                  }`}
                  type={question?.systemQuestion === 'Email' ? 'email' : 'text'}
                  {...allFieldAttr}
                  onChange={(e) => {
                    let text = e.target.value;

                    if (
                      question?.systemQuestion === 'Email' ||
                      !disallowedCharacters.test(text)
                    ) {
                      let combinedText =
                        question?.answer?.toString().split(';') || [];
                      combinedText[index] = text;
                      let currentIndex = 0;
                      for (const val of combinedText) {
                        if (!val) {
                          combinedText[currentIndex] = '';
                        }
                        currentIndex += 1;
                      }
                      handleChange(combinedText?.join(';'), 'answer');
                    }
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
  function handleChange(value: string | boolean, field: string) {
    if (!dirty) {
      setDirty(true);
    }

    let newValues = {
      ...question,
      [field]: value,
    };
    handleValue(quesNo ? +quesNo - 1 : 0, newValues);
  }
}
