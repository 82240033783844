import { useEffect, useState } from 'react';
import { getPhotoUrl } from '../../../utils/photo';

export default function OptionImg(props: {
  photo: string;
  accId: string;
  orderFormId: string;
  fullImage?: boolean;
}) {
  const { photo, accId, orderFormId, fullImage = false } = props;

  const [uri, setUri] = useState('');

  async function getCurrPhoto() {
    try {
      const url = await getPhotoUrl({
        photoRef: photo,
        orderId: orderFormId,
        type: undefined,
        path: 'questions',
        folderId: accId,
      });
      if (url.uri) {
        setUri(url.uri);
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (photo && accId && orderFormId) {
      getCurrPhoto();
    }
  }, [photo, accId, orderFormId]);
  return (
    <img
      src={uri}
      className={!fullImage ? 'rounded-xl cookies_options' : ''}
      style={{
        maxHeight: '85vh',
      }}
      alt="dropdown"
    />
  );
}
