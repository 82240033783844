import Layout from '../components/Layout';

export default function DeletionInformation() {
  return (
    <Layout informationalPage={true}>
      <div className="container mx-auto mx-auto flex flex-col shadow-lg rounded-2xl overflow-hidden mb-16">
        <div className="pb-8 w-5/6 lg:w-5/6 mx-auto mt-4">
          <div className="w-full">
            <div className="grid grid-cols-1 p-2 mt-4">
              <div className="flex flex-col">
                <div className="box">
                  <h1 className="text-3xl md:text-4xl text-gray font-serif">
                    User Deletion Information
                  </h1>
                </div>
                <div className="pt-1 flex flex-col text-robin font-serif">
                  Once you delete your account we will remove your user
                  information from our live user collection as well as congito.
                  We will save that information in a backup collection along
                  with your business account's information. <br />
                  <br />
                  Please be sure to cancel your subscription before initiating
                  the deletion process as we don't want you to be billed for an
                  application you're no longer using.
                  <br />
                  <br />
                  If you would like to completely remove your information please
                  reach out to{' '}
                  <a href="mailto:hello@siftmobileapp.com">
                    hello@siftmobileapp.com
                  </a>
                </div>
                <div className="flex flex-col text-robin font-serif">
                  <h3 className="text-2xl md:text-3xl font-serif text-robin mt-4">
                    Manual Deletion Steps
                  </h3>
                  <ol className="pt-1">
                    <li>Log into the Sift Mobile app</li>
                    <li className="pt-1">
                      Hit the cog in the bottom right to open up the settings
                    </li>
                    <li className="pt-1">
                      Scroll the the very bottom and hit the "DELETE ACCOUNT"
                      button
                    </li>
                    <li className="pt-1">
                      Once you confirm your account will be deleted
                    </li>
                  </ol>
                </div>
                <div className="flex flex-col text-robin font-serif">
                  <h3 className="text-2xl md:text-3xl font-serif text-robin mt-4">
                    Delete Without App
                  </h3>
                  <div className="pt-1">
                    Please reach out to{' '}
                    <a href="mailto:hello@siftmobileapp.com">
                      hello@siftmobileapp.com
                    </a>{' '}
                    with your email you used to create your account.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
