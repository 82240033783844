import Spinner from './Spinner';

export default function HomeSpinner(props: { active: boolean }) {
  const { active } = props;
  return (
    <>
      {active ? (
        <div className='home-spinner'>
          <Spinner />
        </div>
      ) : null}
    </>
  );
}
