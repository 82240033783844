import QuestionTitle from './QuestionTitle';
import TimePicker from 'react-time-picker';
import { DateSelection } from '../../../models/Question.interface';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from 'react';

export default function DateSelectionQues(props: {
  title?: string;
  quesNo?: number;
  time?: boolean;
  value?: any;
  handleValue: (index: any, newValues: any) => void;
  question: DateSelection;
  excludeDateRange?: Date[];
  invalid?: string[];
}) {
  const {
    quesNo,
    title,
    time,
    value,
    handleValue,
    question,
    excludeDateRange = [],
    invalid,
  } = props;

  const [dirty, setDirty] = useState(false);

  // on invalid update dirty
  useEffect(() => {
    setDirty(false);
  }, [invalid]);

  return (
    <div className="mt-8">
      <QuestionTitle quesNo={quesNo} title={title} />
      <div
        className={`grid md:gap-8 gap-2 py-2 grid-cols-1 mt-4 ${
          time ? 'md:grid-cols-2' : ''
        }`}
      >
        <div className="box_shadow_input bg-white">
          <DatePicker
            className={`short_text_input ${!!invalid && !dirty && 'invalid'}`}
            dateFormat="MM dd	yyyy"
            placeholderText="Please select available date"
            selected={value ? new Date(value) : undefined}
            onChange={(value: any) => {
              handleChange(value, 'answer', 'date');
            }}
            minDate={new Date()}
            excludeDates={excludeDateRange}
          />
        </div>
        {time && (
          <div className="box_shadow_input bg-white">
            <TimePicker
              className={`short_text_input ${!!invalid && !dirty && 'invalid'}`}
              clearIcon={null}
              hourPlaceholder="00"
              minutePlaceholder="00"
              secondPlaceholder="00"
              format="hh mm a"
              disableClock={true}
              value={value ? new Date(value) : ''}
              onChange={(value) => {
                handleChange(moment(value, 'hh mm'), 'answer', 'time');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
  function handleChange(
    value: string | boolean | Date | unknown,
    field: string,
    dateOrTime: string | boolean
  ) {
    if (!dirty) {
      setDirty(true);
    }

    let newValues = {
      ...question,
      [field]: value,
    };
    if (dateOrTime) {
      const currentDT = moment(question.answer || moment());
      const updatedDT = moment((value as Date) || moment());

      if (dateOrTime === 'time') {
        newValues = {
          ...question,
          [field]: currentDT
            .set('hours', updatedDT.hours())
            .set('minutes', updatedDT.minutes()),
        };
      } else if (dateOrTime === 'date') {
        newValues = {
          ...question,
          [field]: currentDT
            .set('date', updatedDT.date())
            .set('month', updatedDT.month())
            .set('year', updatedDT.year()),
        };
      }
    }
    handleValue(quesNo ? quesNo - 1 : 0, newValues);
  }
}
