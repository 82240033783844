import { Md5 } from 'ts-md5/dist/md5';

export function getInitials(value: string) {
  const result = value
    ?.split(' ')
    ?.reduce((acc, subname) => acc + subname[0], '');
  return result === 'undefined' ? '' : result;
}

export const getDatesBetween = (
  startDate: Date,
  endDate: Date,
  includeEndDate?: boolean
) => {
  const dates = [];
  const currentDate = startDate;
  while (currentDate < endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  if (includeEndDate) dates.push(endDate);
  return dates;
};


export function convertBase64ToMd5Hash(base64string: string) {
  return Md5.hashStr(base64string);
}